import React, {
  Component,
  useState,
  Fragment,
  useEffect,
  useMemo,
} from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactDOM from "react-dom";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery.js/dist/css/lightgallery.css";
import "../../styles/_carousel.scss";
import { Button, Card } from "react-bootstrap";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import ImageLoader from "../common/ImageLoader";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { sendEventDataToTagManager } from "../../hooks/tagManagerHelper";
import { useNavigate } from "react-router-dom";
import SwiperGallery from "./SwiperGallery";

export default function ProductDetailComponent({ ProductData }) {
  const [loadedThumbnails, setLoadedThumbnails] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [selectedSize, setSelectedSize] = useState("");
  const [showError, setShowError] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [totalSlides, setTotalSlides] = useState();
  const sizes = ProductData.size && ProductData.size.split(",");
  const isSoldOut =
    ProductData?.remark?.includes("nostock") || ProductData?.stock <= 0;

  const isSareeCategory = ProductData?.category?.includes(
    "IhRSrDqe7OtezxM73vv1"
  ); // false if category includes the sarees size is expemted

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function calculateDiscountPercentage(price, specialPrice) {
    return Math.round(((price - specialPrice) / price) * 100);
  }

  const addToCartHandler = (e) => {
    e.preventDefault();
    if (selectedSize === "" && !isSareeCategory) {
      setShowError(true);
      return;
    }
    const specialPriceNumber = Number(ProductData.specialPrice);

    let eventData = {
      items: [
        {
          item_name: ProductData.title,
          item_id: ProductData.productId,
          price: specialPriceNumber.toFixed(2),
          item_category: ProductData.category[0],
          item_variant: selectedSize,
          quantity: 1,
        },
      ],
    };
    sendEventDataToTagManager({
      event: "add_to_cart",
      ecommerce: { ...eventData },
    });
    dispatch(
      cartActions.addItemToCart({
        uniqueId: ProductData.productId + selectedSize,
        productId: ProductData.productId,
        price: ProductData.specialPrice,
        stock: ProductData.stock,
        size: selectedSize,
        name: ProductData.title,
        image: ProductData.thumbnailImage,
        category: ProductData.category[0],
        instructions: "",
      })
    );

    return true;
  };

  function handleSelectedSize(e, sizee) {
    e.preventDefault();
    setSelectedSize(sizee);
  }

  function SizeButton({ sizee }) {
    return (
      <Button
        className={`rounded-circle bg-transparent text-dark me-2 mb-3 ${
          selectedSize === sizee ? "btn-success" : "btn-dark"
        }`}
        style={{
          width: "50px",
          fontSize: "12px",
          height: "50px",
          borderWidth: selectedSize === sizee ? "3px" : "",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        }}
        onClick={(e) => handleSelectedSize(e, sizee)}
      >
        {sizee}
      </Button>
    );
  }

  useEffect(() => {
    let timer;
    if (showError) {
      timer = setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showError]);

  useEffect(() => {
    if (ProductData && ProductData.images) {
      // Here we assign the images array from ProductData to loadedThumbnails
      setLoadedThumbnails(ProductData.images);

      // Preload images
      ProductData.images.forEach((src) => {
        const image = new Image();
        image.src = src;
      });
    }
  }, [ProductData]);

  const handleImageClick = (e, index) => {
    e.preventDefault();
    console.log("Image Clicked " + index);
    setPhotoIndex(index);
  };

  if (!ProductData) {
    return <div>Loading...</div>; // render some loading spinner or fallback UI
  }

  function handleBuyNow(e) {
    if (!addToCartHandler(e)) {
      // If addToCartHandler returns false, exit early without navigating
      return;
    }

    navigate("/checkout");
  }
  return (
    <Fragment>
      <Container fluid={true} className="BetweenTwoSection py-5">
        {/* {console.log(ProductData)} */}
        <Row className="py-5 px-2">
          <Col
            className="shadow-sm bg-white pb-3 mt-4"
            md={12}
            lg={12}
            sm={12}
            xs={12}
          >
            <Row>
              <Col className="p-3" md={7} lg={4} sm={12} xs={12}>
                <div className="d-flex flex-column">
                  <div className="order-lg-1 order-2">
                    <SwiperGallery
                      currentSlide={currentSlide}
                      setCurrentSlide={setCurrentSlide}
                      setTotalSlides={setTotalSlides}
                      totalSlides={totalSlides}
                      loadedThumbnails={loadedThumbnails}
                      handleImageClick={handleImageClick}
                    />
                  </div>
                  <div className="d-none  d-md-block  d-lg-none order-3">
                    <p style={{ textAlign: "justify" }}>
                      {ProductData.description}
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="p-3 " md={5} lg={8} sm={12} xs={12}>
                {/* <h5 className="Product-Name">{ProductData.title}</h5> */}
                <h1
                  className="product-name-on-card  text-start justify"
                  style={{
                    color: "#181818",

                    lineHeight: "1.3em",

                    marginBottom: "4px",
                    textTransform: "capitalize",
                    fontSize: "18px",

                    fontWeight: 600,
                    whiteSpace: "normal",
                    textAlign: "justify",
                    // maxWidth: "100%", // make sure this is same as image width
                  }}
                >
                  {ProductData.title}
                </h1>

                <p
                  className="product-price-on-card text-start"
                  style={{
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "normal",
                    color: "#00593b",
                  }}
                >
                  &#8377;{ProductData.specialPrice}
                  {ProductData.price > 0 && (
                    <>
                      {" "}
                      <span
                        className="old-price"
                        style={{
                          fontSize: "13px",
                          marginRight: "0",
                          // marginLeft: "5px",
                          color: "#21201d",
                          fontWeight: 600,
                          lineHeight: "24px",
                          letterSpacing: "normal",
                        }}
                      >
                        <s>&#8377;{ProductData.price}</s>
                      </span>
                      <span
                        className="percentage-price"
                        style={{
                          color: "#770806",
                          fontSize: "13px",
                          fontWeight: 400,
                        }}
                      >
                        {ProductData.price && ProductData.specialPrice
                          ? `(${calculateDiscountPercentage(
                              ProductData.price,
                              ProductData.specialPrice
                            )}% off)`
                          : ""}
                      </span>
                    </>
                  )}
                </p>

                {sizes &&
                  sizes.map((sizee, index) => (
                    <SizeButton sizee={sizee} key={sizee + index} />
                  ))}
                {showError && (
                  <div style={{ color: "red" }}>
                    <h4>Size should not be empty</h4>
                  </div>
                )}
                {ProductData["stock"] > 0 && (
                  <p>Stock: {ProductData["stock"]}</p>
                )}

                {isSoldOut && (
                  <p className="text-danger fw-bold h4">Sold out</p>
                )}
                <div className="d-grid gap-2 d-lg-block mb-3">
                  <button
                    disabled={isSoldOut}
                    onClick={addToCartHandler}
                    type="button"
                    className="btn me-3 btn-success"
                  >
                    ADD TO CART
                  </button>
                  {/* <button
                    type="button"
                    onClick={handleBuyNow}
                    id="buy-now-clicked"
                    className="btn me-3 btn-success"
                  >
                    BUY IT NOW
                  </button> */}
                </div>
                <p
                  className="d-md-none d-lg-block"
                  style={{ textAlign: "justify" }}
                >
                  {ProductData.description}
                </p>
                {ProductData?.otherFeatures?.length > 0 ? (
                  <Card className="border-0 bg-light mb-4 shadow-strong">
                    <Card.Body>
                      <h5>Features </h5>
                      <Row
                        as="ul"
                        xs={1}
                        md={7}
                        className="list-unstyled gy-2 mb-0 text-dark "
                      >
                        {ProductData.otherFeatures.map((title, indx) => (
                          <ul className="list-unstyled">
                            {" "}
                            <Col key={indx} as="li" className="d-flex">
                              <span className="text-success fs-lg me-2">
                                &#8226;
                              </span>{" "}
                              {title}
                            </Col>
                          </ul>
                        ))}
                      </Row>
                    </Card.Body>
                  </Card>
                ) : (
                  <></>
                )}

                <h6 className="mt-2">
                  Brand : <b>{ProductData.brand}</b>
                </h6>

                <h6 className="mt-2">
                  Product Code : <b>{ProductData.productId}</b>
                </h6>

                {/* <div className="input-group mt-3">
                  <button className="btn btn-danger m-1">
                    <i className="fa  fa-edit"></i> Edit
                  </button>
                </div> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
