import React from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { cartActions } from "../../store/cart-slice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const CartItem = ({
  image,
  name,
  price,
  quantity,
  productId,
  size,
  uniqueId,
  availableStock,
}) => {
  const dispatch = useDispatch();

  const incrementQuantityHandler = () => {
    if (quantity >= availableStock) {
      // Corrected condition
      MySwal.fire({
        title: "Out of Stock",
        text: `You cannot add more than the available stock. Available stock: ${availableStock}`,
        icon: "warning",
      });
    } else {
      dispatch(cartActions.addItemToCart({ uniqueId: uniqueId }));
    }
  };

  const decrementQuantityHandler = () => {
    dispatch(cartActions.removeItemFromCart(uniqueId));
  };

  const deleteItem = () => {
    dispatch(cartActions.deleteItem(uniqueId));
  };

  return (
    <Container
      style={{
        maxWidth: "340px",
        border: "none",
        margin: "2px 0 20px 0",
        padding: "0",
      }}
      className="card mb-3"
    >
      <Row className="g-0">
        <Col xs={4}>
          <Image
            src={image}
            style={{ height: "150px", width: "100px" }}
            rounded
          />
        </Col>
        <Col
          xs={6}
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "10px",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              margin: "0",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </p>
          <p style={{ fontSize: "12px", color: "#bbb", margin: "0" }}>
            Size: {size}
          </p>
          <p style={{ margin: "0" }}>
            <small style={{ fontSize: "12px", color: "green" }}>
              <b>₹{price}</b>
            </small>
          </p>
          <Col>
            {" "}
            <div
              className="d-flex justify-content-between align-items-center mt-2"
              style={{ border: "1px solid grey", padding: "2px" }}
            >
              <Button
                onClick={decrementQuantityHandler}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "black",
                }}
              >
                -
              </Button>
              <span>{quantity}</span>
              <Button
                onClick={incrementQuantityHandler}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "black",
                }}
              >
                +
              </Button>
            </div>
          </Col>
        </Col>
        <Col
          xs={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FaTrash onClick={deleteItem} size={18} />
        </Col>
        <hr className="my-3"></hr>
      </Row>
    </Container>
  );
};

export default CartItem;
